$(document).ready(() => {
  // 並び順選択時
  $('#select_case_list').change(() => {
    location.href = $('#select_case_list option:selected').val();
  });

  // 症例投稿の詳細ページでサムネイル画像クリック時
  $('.case_page_label_img').click(function() {
    const img = $(this).attr('id').replace('case_page_label_img_', 'case_page_main_img_');
    $('.case_page_main_img').hide();
    $('#' + img).fadeIn(500);
  });
});
