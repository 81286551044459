$(function() {
	//▼ バリデート設定
	var setting = {
		"ApplicationName":{
			"rule":{
				"required":"お名前を入力してください"
			}
		},
		"ApplicationEmail":{
			"rule":{
				"required":"メールアドレスを入力してください",
				"email":"メールアドレスを正しく入力してください"
			},
			"autoHankak":true
		},
		"ApplicationHospitalName":{
			"rule":{
				"required":"勤務先を入力してください"
			}
		},
		"ApplicationTel":{
			"rule":{
				"required":"勤務先電話番号を入力してください"
			},
			"autoHankak":true
		},
		"ApplicationHospitalAddress":{
			"rule":{
				"required":"勤務先住所を入力してください"
			}
		},
		"ApplicationLicense":{
			"rule":{
				"required":"医療資格を選択して下さい"
			}
		},
	};


	//▼ validation.jsを初期化
	$.el_valid.init(setting);

	//▼ フォーム送信前に全項目チェック
	$("#applyButton").click(function(e) {
		var check = $.el_valid.validate_all();
		if(check != true) {
			e.preventDefault();
			alert("入力されていない、又は入力内容が正しくない項目があるので、修正をお願いします。");

			//複数候補チェックボックス等の場合、0番目のIDを採用する
			if($.el_valid.setting[check].onmulticlick) {
				check += '0';
			}
			$('html,body').animate({ scrollTop: ($('#' + check).offset().top - 50) }, 600);
		}
	});

});
