import "slick-carousel";
import { csrfToken } from "rails-ujs";

// Objects added carousel functionality
const slickClass = ".carousel_active";

// Objects not allowed to trigger modals
const noOpenModalClass = "noOpen_modal_slider";

let slickOptions = {
  lazyLoad: "anticipated",
  infinite: false,
};

const modalClass = ".photo_viewer_slideshow";
const premiumMemberLink =
  "https://pr-contents.doctorbook.jp/premium?utm_source=doctorbookacademy&utm_medium=referral&utm_campaign=doctorbookacademy_slide";

(function($) {
  function addSlickCarousel() {
    $(slickClass).each(function() {
      $(this).slick(slickOptions);
      onModalOpen(this);
      swipeSlideLog(this);
    });
  }

  function onModalOpen(card) {
    let isOpenModal = $(card)
      .attr("class")
      .includes(noOpenModalClass);

    if (isOpenModal) return;
    $(card)
      .find(".slick-list")
      .css("cursor", "zoom-in");

    const images = $(card).find(".card_slide_img");
    images.on("click", function() {
      const currentSlider = $(this).closest(".slick-slider");
      const currentSlide = currentSlider.slick("getSlick").currentSlide;
      const clonedImages = currentSlider.find("img").clone();
      let id = currentSlider.data("id");
      let type = currentSlider.data("type");

      $("body").toggleClass("imgGalleryOpen");
      $(modalClass).attr("data-id", id);
      $(modalClass).attr("data-type", type);
      $(modalClass)
        .slick("unslick")
        .empty()
        .append(clonedImages)
        .slick(slickOptions)
        .slick("slickGoTo", currentSlide, true);
      addLinkRedirictTo(".premium_member_slide", premiumMemberLink);

      let idCard = $(this)
        .closest(".slider_wrap")
        .attr("id");

      if (idCard !== undefined) {
        let movieId = idCard.replace("movie-", "");
        $(".gallery_btn_wrapper").removeClass("hidden");
        $(".watch_video").attr("href", "link to movie id");
        $(".favorite_this_movie").attr("data-movie-id", movieId);

        let favorite = $(this)
          .closest(".slider_wrap")
          .attr("data-favorite");

        if (favorite == "true") {
          $(".favorite_this_movie")
            .removeClass("add_to_mylist")
            .addClass("remove_from_mylist")
            .html("マイリストから削除");
        } else {
          $(".favorite_this_movie")
            .removeClass("remove_from_mylist")
            .addClass("add_to_mylist")
            .html("マイリストへ追加");
        }

        // Update href for button watch movie
        $(".watch_video").attr("href", "/movies/" + movieId);
      } else {
        $(".gallery_btn_wrapper").addClass("hidden");
      }

      onModalClose(card);
    });
  }

  function onModalClose(card) {
    $(".galleryBg, .icon_gallery_close").on("click", function() {
      $("body").removeClass("imgGalleryOpen");

      const currentSlide = $(modalClass).slick("getSlick").currentSlide;
      $(card).slick("slickGoTo", currentSlide, true);

      if ($("body").hasClass("galleryUnslick")) {
        setTimeout(() => {
          $("body").removeClass("galleryUnslick");
          $(modalClass).slick(slickOptions);
        }, 300);
      }
      $(".galleryBg, .icon_gallery_close").off("click");
    });
  }

  function onWatchAllImg() {
    $(".icon_square_quadl").on("click", function() {
      $(modalClass)
        .slick("slickSetOption", { lazyLoad: "progressive" }, true)
        .slick("refresh")
        .slick("unslick");
      $("body").addClass("galleryUnslick");
      selectedImgIndex();
    });
  }

  function selectedImgIndex() {
    $(`${modalClass} img`).each(function(index) {
      $(this).on("click", function() {
        slickGoTo(index);
      });
    });
  }

  function slickGoTo(index) {
    $("body").removeClass("galleryUnslick");
    $(modalClass)
      .slick(slickOptions)
      .slick("slickGoTo", index, true)
      .slick("refresh");
    addLinkRedirictTo(".premium_member_slide", premiumMemberLink);
  }

  function slideCounter() {
    $(".slider_wrap").each(function() {
      const sliderWrap = $(this);
      const slider = sliderWrap.find(".slick-slider");
      const current = slider.slick("slickCurrentSlide") + 1;
      const totalSlides = slider.slick("getSlick").slideCount;

      sliderWrap.find(".badge").text(`${current}/${totalSlides}`);
      slider.on("afterChange", function(event, slick, currentSlide) {
        const current = currentSlide + 1;
        const totalSlides = slick.slideCount;

        sliderWrap.find(".badge").text(`${current}/${totalSlides}`);

        if (slider.attr("class").includes("photo_viewer_slideshow")) {
          $(".all_slide_text").text(`全てのスライド（${totalSlides}枚）`);
        }
      });
    });
  }

  function movieFavoriteAction() {
    $(".add_to_mylist, .remove_from_mylist").on("click", function(e) {
      e.preventDefault();

      let favorite = $(this);
      let movieId = favorite.attr("data-movie-id");
      let url = "/api/favorite_movies/refresh";
      let data = {
        movie_id: movieId,
      };

      $.ajax({
        type: "POST",
        url: url,
        headers: {
          "X-CSRF-Token": csrfToken(),
        },
        data: data,
      }).done(function() {
        let siblingButton = $("#movie-" + movieId)
          .siblings()
          .find(".favorite_this_movie");

        if (favorite.hasClass("add_to_mylist")) {
          $("#movie-" + movieId).attr("data-favorite", true);

          // Change button add/remove movie to mylist
          favorite.removeClass("add_to_mylist").addClass("remove_from_mylist");
          favorite.html("マイリストから削除");

          // Exists another button out of modal and have action add/remove movie to mylist. Have to update that button.
          siblingButton
            .removeClass("episode_btn_fav")
            .addClass("episode_btn_out");
          siblingButton.html("マイリストから削除");
        } else {
          $("#movie-" + movieId).attr("data-favorite", false);
          // Change button add/remove movie to mylist
          favorite.removeClass("remove_from_mylist").addClass("add_to_mylist");
          favorite.html("マイリストへ追加");

          // Exists another button out of modal and have action add/remove movie to mylist. Have to update that button.
          siblingButton
            .removeClass("episode_btn_out")
            .addClass("episode_btn_fav");
          siblingButton.html("マイリストへ追加");
        }
      });
    });
  }

  function addLinkRedirictTo(element, url) {
    $(element).on("click", function() {
      window.open(url, "_blank", "noopener");
    });
  }

  let previousSlide = null;
  function swipeSlideLog(card) {
    $(card).on("afterChange", function(event, slick, currentSlide) {
      let user_id = $(this).data("user-id");

      // Log will be created if current user present and user's action is not open/close modal
      if (
        user_id !== "" &&
        ((previousSlide != null && previousSlide != currentSlide) ||
          (currentSlide == 1 && previousSlide == null))
      ) {
        let type = $(this).attr("data-type");
        let id = $(this).attr("data-id");
        let type_id = type + "_id";

        let totalSlides = $(this).slick("getSlick").slideCount;
        let endEdgeReached = currentSlide + 1 == totalSlides;

        let data = {
          user_id: user_id,
          max_reached_page: currentSlide + 1,
          end_edge_reached: endEdgeReached,
        };
        data[type_id] = id;
        $.ajax({
          type: "POST",
          url: "/api/daily_" + type + "_swipes",
          headers: {
            "X-CSRF-Token": csrfToken(),
          },
          data: data,
        });
      }
      previousSlide = currentSlide;
    });
  }

  $(function() {
    addSlickCarousel();
    onWatchAllImg();
    slideCounter();
    movieFavoriteAction();
  });
})(jQuery);
