"use strict";

const searchCategory = document.querySelectorAll(".searchSide_category-item");
const searchCategories = Array.from(searchCategory);

searchCategories.forEach(function (target) {
  target.addEventListener("mouseover", openCategoryList);
  function openCategoryList() {
    const content = this.children[1];
    content.classList.add("is-open");
    target.addEventListener("mouseout", closeCategoryList);
    function closeCategoryList() {
      content.classList.remove("is-open");
    }
  }
});
