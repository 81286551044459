document.addEventListener('DOMContentLoaded', () => {
  $('.js_catCheckList .parent .catLabel').click(function(){
    $(this).toggleClass('is_active').next('.innerList').slideToggle('fast');
    return false;
  });

  $('.js_catCheckList .parent > input[type=checkbox]').change(function(){
    if($(this).prop('checked') === true){
        $(this).closest('.parent').find('.innerList input[type=checkbox]').prop('checked', true);
    }else{
        $(this).closest('.parent').find('.innerList input[type=checkbox]').prop('checked', false);
    }
  });
  $('.innerList input[type=checkbox]').change(function(){
    var totalLen = $(this).closest('.parent').find('.innerList input[type=checkbox]').length,
        checkLen = $(this).closest('.parent').find('.innerList input[type=checkbox]:checked').length;
    if(totalLen === checkLen){
        $(this).closest('.parent').children('input[type=checkbox]').prop('checked', true);
    }else{
        $(this).closest('.parent').children('input[type=checkbox]').prop('checked', false);
    }
  });

  $('.search_movie_detail_tag .listAccBtn').click(function(){
    $(this).toggleClass('is_active');
    $('.search_movie_detail_tag .accList > li:nth-child(n+5)').slideToggle('fast');
    return false;
  });
  $('.search_movie_detail_tag .accList .accBtn').click(function(){
    $(this).toggleClass('is_active').next('.checkList').slideToggle('fast');
    return false;
  });
});
