<template>
  <div>
    <multiselect
      v-model="selected"
      :taggable="true"
      :options="hospitals"

      :show-labels="false"
      :placeholder="placeholder"
      :clear-on-select="false"
      :internal-search="false"
      :loading="loading"
      :disabled="!prefId"
      label="label"
      track-by="id"
      tag-placeholder="Enterキーで確定"
      @search-change="findOptions"
      @tag="addHospital"
    >
      <template v-slot:singleLabel>
        {{ hospitalName }} <small v-if="hospital && hospital.address">{{ hospital.address }}</small>
      </template>
      <template v-slot:noOptions>
        <span v-if="prefId">選択できる勤務先が見つかりません。</span>
        <span v-else>都道府県を選択後、キーワードを入力してください。</span>
      </template>
    </multiselect>
    <input
      :name="name"
      v-model="value"
      type="hidden"
    >
    <p
      v-if="!prefId"
      class="form-msg error"
    >都道府県を選択後、勤務先を設定してください。</p>
  </div>
</template>
<script>
  import Multiselect from 'vue-multiselect'
  import axios from 'includes/axios'
  import _ from 'lodash';

  export default {
    components: { Multiselect },
    props: {
      prefId: {
        type: String,
        requires: true,
        default: null
      },
      name: {
        type: String,
        requires: true,
        default: null
      },
      value: {
        type: String,
        requires: true,
        default: null
      },
      hospitalName: {
        type: String,
        requires: true,
        default: null
      }

    },
    data () {
      return {
        hospitals: [],
        hospital: null,
        loading: false
      }
    },
    computed: {
      placeholder: function() {
        if(this.prefId) {
          return "キーワードを入力してクリニックを検索/選択してください"
        }else{
          return "最初に都道府県を選択してください"
        }
      },
      selected: {
        get() {
          return this.value
        },
        set(hospital) {
          this.hospital = hospital
          if (hospital) {
            this.$emit('input', hospital.id)
            this.$emit('update:hospitalName', hospital.value)
            this.$emit('update:hospitalPhone', hospital.phone)
            this.$emit('update:hospitalAddress', hospital.address)
          } else {
            this.$emit('input', '')
            this.$emit('update:hospitalName', '')
          }
        }
      },
    },
    methods: {
      addHospital (newHospital) {
        const hospital = {
          label: newHospital,
          value: newHospital,
          phone: '',
          address: ''
        }
        this.hospitals.push(hospital)
        this.selected = hospital
      },
      optionMapping(hospital) {
        return { id: hospital.id, title: hospital.label }
      },
      findOptions : _.debounce(function(query) {
        if (!query) return
        this.loading = true
        return axios.get('/api/hospitals.json', { params: { 'term': query, 'pref_id': this.prefId } })
          .then((ret) =>{
            this.loading = false
            this.hospitals = ret.data;
          })
      }, 300, { 'maxWait': 1000 })
    },
  }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.multiselect >>> input {
  border: 0;
  box-shadow: none;
  margin: 0;
  margin-top: -2px;
  padding: 0;
  font-size: 11px;
}

.multiselect >>> .multiselect__single {
  background: none;
  color: black !important;
}
.multiselect >>> .multiselect__single small {
  color: #bbb;
}

.multiselect >>> .multiselect__tags {
  border: 1px solid #bbb;
  background: linear-gradient(to bottom, #fff, #f9f9f9);
}

.multiselect >>> .multiselect__option--highlight {
  background: #fffddd
}
</style>
