<template>
  <div :class="{vue_modal: true, vue_modal_wrap: true, display: display}">
    <div
      class="vue_modal_overlay"
      @click.stop.prevent="close"
    >
      <div
        class="vue_modal_content"
        @click.stop
      >
        <div class="vue_modal_title"><slot name="title" /></div>
        <div
          class="close_button"
          @click.stop.prevent="close"
        >
          <i class="fa fa-close" />
        </div>
        <slot
          v-if="modalActive"
          name="body"
        />
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      display: {
        type: Boolean,
        requires: true,
        default: false
      },
    },
    data () {
      return {
        modalActive: this.display,
        timer: null
      }
    },
    watch: {
      display(value) {
        clearTimeout(this.timer)
        if(!value) {
          // モーダルの中身を破棄するタイミングをモーダルが消えるまでタイミングを遅らせる
          this.timer = setTimeout( () => this.modalActive = false, 500 )
        } else {
          this.modalActive = value
        }
      }
    },
    methods: {
      close(){
        this.$emit('close')
      }
    }
  }

</script>
<style scoped>
.vue_modal_overlay{
    display: flex;
    justify-content: center;
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
    opacity: 0;
    transition: opacity 0.5s, transform 0s 0.5s;
    transform: scale(0);
}

.vue_modal_wrap.display .vue_modal_overlay {
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.5s;
}

.vue_modal_content {
    position: relative;
    align-self: center;
    width: 90%;
    max-width: 800px;
    padding: 50px 30px 15px;
    box-sizing: border-box;
    background: #fff;
    line-height: 1.4em;
    transition: 0.5s;
}
@media (min-width: 668px) {
  .vue_modal_content {
    width: 60%;
  }
}
.vue_modal_title {
  position: absolute;
  top: 14px;
  font-weight: bold;
  font-size: 1.3rem;
}
.vue_modal >>> input {
  width: 100%;
  margin: auto;
}
</style>
