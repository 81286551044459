$(() => {
    /* PC用 */
    if (window.matchMedia("(min-width: 668px)").matches) {
        // ドロップダウンリストを開く
        $(".search_group_box_tag dt").on("click", function(e) {
            e.stopPropagation();
            $(this).next().slideToggle(300);
            $(this).toggleClass("active");
        });
        // ドロップダウンリストがチェックボックスのクリック時に閉じないようにする
        $(".search_group_box_tag dd").on("click", function(e) {
            e.stopPropagation();
        });
        // 背景クリック時にドロップダウンリストを閉じるようにする
        $(document).on("click", function() {
            $(".search_group_box_tag dd").hide();
            $(".search_group_box_tag dt").removeClass("active");
        });
    }
});
