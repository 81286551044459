require('jquery-ui/ui/widgets/datepicker');
require('jquery-ui/ui/i18n/datepicker-ja');

/* 詳細検索フォーム 開催日 カレンダー表示 */
$(function() {
    $("#events_calendar").datepicker({
        dateFormat: 'yy-mm-dd',
        showOn: "both",
        showButtonPanel: true,
        beforeShow: function(input, inst) {
            var calendar = inst.dpDiv;
            setTimeout(function() {
                calendar.position({
                    my: 'left top+5px',
                    at: 'left bottom',
                    of: input
                });
            }, 1);
            $('html').addClass('noscroll');
        },
        onClose: function() {
            $('html').removeClass('noscroll');
        }
    });
});
