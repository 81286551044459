$(document).ready(() => {
  // セミナー・イベントをもっと見るリンクのクリック時
  $('#more_events_show_link').click((e) => {
    e.preventDefault()
    $('div.events table tr:hidden').show()
    $('#more_events_show_link').remove()
  });

  // 製品カテゴリ選択時
  $('#select_product_category').change(() => {
    location.href = $('#select_product_category option:selected').val();
  });
});
