import { csrfToken } from 'rails-ujs';

$(() => {
  $('#favCheck').on('change', function () {
    const current = parseInt($('#favCount').text());
    if ($(this).prop('checked')){
      $('#favCount').text(current + 1);
    }else{
      $('#favCount').text(current - 1);
    }

    $.ajax({
      type: 'POST',
      url: '/api/user_likes/refresh',
      data: {
        movie_id: $(this).data("movie-id")
      },
      headers: {
        'X-CSRF-Token': csrfToken()
      },
    });
  });
});
