if (typeof $.el_valid === typeof undefined) {
	//自分自身 (仮想パッケージ)
	$.el_valid = {};
	//設定配列
	$.el_valid.setting = {};

	//▼ 初期化関数
	$.el_valid.init = function(arg) {
		//設定配列に代入
		$.el_valid.setting = arg;

		//各イベントハンドラに追加
		for ( var key in arg) {
			//"on"指定 (デフォルトblur)
			var e = 'blur';
			if (arg[key].on) {
				e = arg[key].on;
			}
			$('#' + key).bind(e, key, function(event) {
				$.el_valid.validate(event.data);
			});

			//"onmulticlick"指定 (チェックボックス等の複数候補に全指定する)
			if(arg[key].onmulticlick) {
				$("input[id^=" + key + "]").bind('click', key, function(event) {
					$.el_valid.validate(event.data);
				});
			}

			//"select"指定 ()
			if(arg[key].onselectclick) {
				$('#' + key).bind('click', key, function(event) {
					$.el_valid.validate(event.data);
				});
			}
		}
	}

	//▼ すべての要素をバリデートする
	// (最初に失敗した要素を返す)
	$.el_valid.validate_all = function() {
		var result = true;
		//各要素ごと
		for ( var key in $.el_valid.setting) {
			var res = $.el_valid.validate(key);
			if (res == false) {
				if(result == true) {
					result = key;
				}
			}
		}
		return result;
	}

	//▼ 指定IDの要素をバリデートする
	// (OKでtrue, NGでfalse を返す)
	$.el_valid.validate = function(id) {
		var sets = $.el_valid.setting[id];

		// 指定ID要素が存在しない場合はスキップ
		if(!sets['onmulticlick'] && (!($('#' + id).get(0))) || (sets['onmulticlick'] && !($("input[id^='" + id + "']").get(0)))) {
			return true;
		}

		//全角→半角変換
		if(sets['autoHankak']) {
			var val = $('#' + id).val();
			val = changeZenToHan(val);
			$('#' + id).val(val);
		}

		//空許可で空の場合はtrueを返す
		if(sets['allowEmpty'] && !($('#' + id).val())) {
			$.el_valid.clear_only_err(id);
			return true;
		}

		//各ルールごと
		for(var r in sets['rule']) {
			var msg = sets['rule'][r];

			if (typeof $.el_valid["rule_" + r] === 'function') {
				if (!$.el_valid["rule_" + r](id)) {
					//validate NG
					$.el_valid.show_err(id, msg);
					return false;
				}
			}
		}

		//すべてのvalidate OK
		$.el_valid.clear_err(id);
		return true;
	};

	//▼ エラー表示を出す
	$.el_valid.show_err = function(id, msg) {
		$('#e_'+id).html(msg);
		$('#'+id).css("background","#ffe7f0");
		if($('#n_'+id)[0]) {
			$('#n_'+id).css("display","inline-block");
		}
		if($('#o_'+id)[0]) {
			$('#o_'+id).hide();
		}
	};

	//▼ エラー表示を消す
	$.el_valid.clear_err = function(id) {
		$('#e_'+id).html('');
		$('#'+id).css("background","");
		if($('#n_'+id)[0]) {
			$('#n_'+id).hide();
		}
		if($('#o_'+id)[0]) {
			$('#o_'+id).css("display","inline-block");
		}
	};

	//▼ エラー表示を消す(OKはダサない)
	$.el_valid.clear_only_err = function(id) {
		$('#e_'+id).html('');
		$('#'+id).css("background","");
		if($('#n_'+id)[0]) {
			$('#n_'+id).hide();
		}
	};

	//▼ ----- 各バリデーションルール -----
	// (OKでtrue, NGでfalse を返す)

	// 必須
	$.el_valid.rule_required = function(id) {
		if($('#' + id).val() && !$('#' + id).val().match(/^([\s　])+$/)) {
			return true;
		} else {
			return false;
		}
	};

	// 必須 (チェックボックス)
	$.el_valid.rule_required_checkbox = function(id) {
		var check = false;
		$("input[id^=" + id + "]").each(function() {
			if($(this).is(':checked')) {
				check = true;
			}
		});
		return check;
	};

	// 必須 (ラジオボタン)
	$.el_valid.rule_required_radio = function(id) {
		var check = false;
		$("input[id^=" + id + "]").each(function() {
			if($(this).is(':checked')) {
				check = true;
			}
		});
		return check;
	};

	// メールアドレス
	$.el_valid.rule_email = function(id) {
		var val = $('#' + id).val();
		if(!(val) || val.match(/^([a-z0-9\+_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,}$/i)) {
			return true;
		} else {
			return false;
		}
	};

	// 電話番号
	$.el_valid.rule_tel = function(id) {
		var val = $('#' + id).val();
		if((val) && val.match(/^\d{2,4}(-)*\d{2,4}(-)*\d{4}$/)) {
			return true;
		} else {
			return false;
		}
	};

	// 郵便番号
	$.el_valid.rule_zipcode = function(id) {
		var val = $('#' + id).val();
		if((val) && val.match(/^\d{3}(-)*\d{4}$/)) {
			return true;
		} else {
			return false;
		}
	};

	// 文字数8文字以上
	$.el_valid.rule_minLength = function(id) {
		var val = $('#' + id).val();
		if((val) && val.length >= 8) {
			return true;
		} else {
			return false;
		}
	};

	// 卒業大学
	$.el_valid.rule_college = function(id) {
		var license_id = $("[name='data[User][license]']:checked").val();
		var college_id = $('#UserCollegeId').val();
		if(($.inArray(parseInt(license_id), [1, 4]) >= 0 && (parseInt(college_id) > 0)) || ($.inArray(parseInt(license_id), [1, 4]) < 0)) {
			return true;
		} else {
			return false;
		}
	};

	// 卒業大学　自由入力
	$.el_valid.rule_college_other = function(id) {
		var license_id = $("[name='data[User][license]']:checked").val();
		var college_id = $('#UserCollegeId').val();
		var college_other = $('#UserCollegeOther').val();
		if(($.inArray(parseInt(license_id), [1, 4]) < 0) || (parseInt(college_id) != 999) || (parseInt(college_id) == 999 && (college_other))) {
			return true;
		} else {
			return false;
		}
	};
}


// ▼ 全角→半角変換
function changeZenToHan(str) {

	var han = new Array('1','2','3','4','5','6','7','8','9','0','a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','@','-','-','.',',',':');
	var zen = new Array('１','２','３','４','５','６','７','８','９','０','ａ','ｂ','ｃ','ｄ','ｅ','ｆ','ｇ','ｈ','ｉ','ｊ','ｋ','ｌ','ｍ','ｎ','ｏ','ｐ','ｑ','ｒ','ｓ','ｔ','ｕ','ｖ','ｗ','ｘ','ｙ','ｚ','Ａ','Ｂ','Ｃ','Ｄ','Ｅ','Ｆ','Ｇ','Ｈ','Ｉ','Ｊ','Ｋ','Ｌ','Ｍ','Ｎ','Ｏ','Ｐ','Ｑ','Ｒ','Ｓ','Ｔ','Ｕ','Ｖ','Ｗ','Ｘ','Ｙ','Ｚ','＠','－','ー','．','，','：');

	var word = str;

	for(i = 0; i < zen.length; i++){
		while (word.indexOf(zen[i]) >= 0){
			word = word.replace(zen[i],han[i]);
		}
	}
	return word;
}
