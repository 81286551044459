import { csrfToken } from 'rails-ujs';

$(() => {
  //▼ マイリスト登録・解除
  $('.episode_btn_fav, .episode_btn_out').on('click', function (e) {
    e.preventDefault();

    var favorite = $(this);
    var movieId = favorite.data("movie-id");
    var postId = favorite.data("post-id");

    var url = '';
    var data = {};
    if (movieId != null) {
      url = '/api/favorite_movies/refresh';
      data = {
        "movie_id": movieId
      }
    } else if (postId != null) {
      url = '/api/favorite_posts/refresh';
      data = {
        "post_id": postId
      }
    }

    $.ajax({
      type: 'POST',
      url: url,
      headers: {
        'X-CSRF-Token': csrfToken()
      },
      data: data
    }).done(function () {
      let sliderWrap = $('#movie-' + movieId);

      if (favorite.hasClass("episode_btn_fav")) {
        favorite.removeClass("episode_btn_fav").addClass("episode_btn_out");
        favorite.html('マイリストから削除');
        if (sliderWrap.length > 0) {
          sliderWrap.attr('data-favorite', true);
        }
      } else {
        favorite.removeClass("episode_btn_out").addClass("episode_btn_fav");
        favorite.html('マイリストへ追加');
        if (sliderWrap.length > 0) {
          sliderWrap.attr('data-favorite', false);
        }
      }
    });
  });
});
