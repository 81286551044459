document.addEventListener('DOMContentLoaded', function() {

// 検索フィールドにフォーカスをあてる
$('#searchOpen').click(function(){
  $('.sp-keywordSearch .keywordSearch').focus();
});
$('.sp-searchClose').click(function(){
  $('.sp-keywordSearch .keywordSearch').blur();
});

//pc : アコーディオン開閉機能
const OpenButton = document.querySelectorAll(".accordionOpen");
const OpenButtons = Array.from(OpenButton);

OpenButtons.forEach(function (target) {
  target.addEventListener("mouseover", openAccordion);
  function openAccordion() {
    const content = this.children[1];
    content.classList.add("is-open");
    if (content.classList.contains("al-category")) {
      content.classList.add("hoverlink");
      const caContent = document.querySelectorAll(".hoverlink");
      const caContents = Array.from(caContent);
      caContents.forEach(function (caTarget) {
        caTarget.addEventListener("mouseout", closeCategoryAccordion);
        function closeCategoryAccordion() {
          content.classList.remove("is-open");
          content.classList.remove("hoverlink");
        }
      });
    } else {
      target.addEventListener("mouseout", closeAccordion);
      function closeAccordion() {
        content.classList.remove("is-open");
      }
    }
  }
});

//pc : 小カテゴリー表示機能
const hoveredCategory = document.querySelectorAll(".accordion-category");
const categories = Array.prototype.slice.call(hoveredCategory, 0);

categories.forEach(function (element) {
  element.addEventListener(
    "mouseover",
    function () {
      element.querySelector(".category-mini-list").classList.add("category-open");
      element.querySelector(".category-mini-list").classList.add("hoverlink");
    },
    false
  );
  element.addEventListener(
    "mouseout",
    function () {
      element.querySelector(".category-mini-list").classList.remove("category-open");
      element.querySelector(".category-mini-list").classList.remove("hoverlink");
    },
    false
  );
});

//sp : 検索ページ表示周辺
///定数宣言
////ナビそのものの開閉
const spSearchOpen = document.getElementById("searchOpen");
const spSearchClose = document.querySelectorAll(".sp-searchClose");
const spSearchCloses = Array.from(spSearchClose);
const spSearchContainer = document.getElementById("sp-searchContainer");
const gnavMenu = document.querySelector(".gnav");
//絞り込みコンテナ用
const spSearchContainerSort = document.getElementById("sp-searchContainerSort");
const spSearchSortOpen = document.getElementById("sp-SCSopen");
////小カテゴリの開閉
const categoryItem = document.querySelectorAll(".category-item");
const categoryItems = Array.from(categoryItem);
const openedMiniCategory = document.getElementById("sp-searchContainer-sub");
const gnavBack = document.getElementById("gnav-back-btn");

if (spSearchOpen != null) {
  spSearchOpen.addEventListener("click", openSpSearch);
}
if (gnavBack != null) {
  gnavBack.addEventListener("click", backSearchTop);
}
if (spSearchSortOpen != null) {
  spSearchSortOpen.addEventListener("click", openSpSearchSort);
}

///処理
////検索オープン
function openSpSearch() {
  spSearchContainer.classList.add("searchActive");
  document.documentElement.classList.add("is_fixed");
  gnavMenu.style.display = "none";
}

////絞り込みオープン
function openSpSearchSort() {
  spSearchContainerSort.classList.add("searchActive");
  document.documentElement.classList.add("is_fixed");
  gnavMenu.style.display = "none";
}

////小カテゴリ→検索TOP
function backSearchTop() {
  openedMiniCategory.classList.remove("in");
  spSearchContainer.classList.add("searchActive");
}

////クローズボタンの共通処理
spSearchCloses.forEach(function (target) {
  target.addEventListener("click", closeSpSearch);
  function closeSpSearch(e) {
    e.preventDefault();

    document.documentElement.classList.remove("is_fixed");
    if (gnavMenu) {
      gnavMenu.style.display = "block";
    }
    if (document.getElementById("sp-searchContainer")?.classList.contains("searchActive")) {
      spSearchContainer.classList.remove("searchActive");
    } else if (document.getElementById("sp-searchContainerSort")?.classList.contains("searchActive")) {
      spSearchContainerSort.classList.remove("searchActive");
    } else {
      openedMiniCategory.classList.remove("in");
    }
  }
});

////小カテゴリオープン
categoryItems.forEach(function (target) {
  target.addEventListener("click", openAccordion);
  function openAccordion() {
    const categoryId = $(this).data("category-id");
    $(".sp_menu_box").hide();
    $(`.sp_menu_box.category_${categoryId}`).show();
    openedMiniCategory.classList.add("in");
    if (document.getElementById("sp-searchContainer").classList.contains("searchActive")) {
      spSearchContainer.classList.remove("searchActive");
    } else {
      spSearchContainerSort.classList.remove("searchActive");
    }
  }
});

});
