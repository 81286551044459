require('jquery-ui/ui/widgets/datepicker');
require('jquery-ui/ui/i18n/datepicker-ja');

$(function() {
    $("#pc_calendar, #sp_calendar").datepicker({
        dateFormat: 'yy-mm-dd',
        beforeShow: function(input, inst) {
            var calendar = inst.dpDiv;
            setTimeout(function() {
                calendar.position({
                    my: 'left top+5px',
                    at: 'left bottom',
                    of: input
                });
            }, 1);
            $('html').addClass('noscroll');
        },
        onClose: function() {
            $('html').removeClass('noscroll');
        }
    });

    // すべてをクリアするをクリックした時
    $('.search_movie_detail_btn_clear').on('click', function(e){
        e.preventDefault();
        $('input[type="checkbox"]:checked').prop('checked', false);
    });
});
