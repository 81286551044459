$(document).ready(function(){
  //▼ ページ内移動時にスクロール
  $('a[href^="#"]' + '[class*=link-scroll]').on('click', function(){
    var speed = 500;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });

  //▼ 外部リンク クリック履歴取得
  $("a[href^='http']").not("[href*='academy.doctorbook.jp']").on('click', function() {
    var src_path = location.pathname;
    var dst_url = $(this).attr("href");

    $.ajax({
      type: 'POST',
      url: '/api/log_clicks',
      data: {
        src_path: src_path,
        dst_url: dst_url
      },
      dataType: 'json',
      async: false,
      timeout: 10000
    });
  });

  //▼ サブミットボタンのDisable化(ダブルクリックを防ぐため)
  $("form").submit(function() {
    var self = this;
    $(":submit", self).prop("disabled", true);
    setTimeout(function() {
      $(":submit", self).prop("disabled", false);
    }, 10000);
  });
});
