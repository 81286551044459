import { csrfToken } from 'rails-ujs';

$(() => {
  //▼ マイリスト登録・解除(LIVE配信セミナー 開催予定一覧)
  $('.live_btn_fav, .live_btn_out').on('click', function (e) {
    e.preventDefault();

    var favorite = $(this);
    var movieId = favorite.data("movie-id");

    var url = '';
    var data = {};
    if (movieId != null) {
      url = '/api/favorite_movies/refresh';
      data = {
        "movie_id": movieId
      }
    }

    $.ajax({
      type: 'POST',
      url: url,
      headers: {
        'X-CSRF-Token': csrfToken()
      },
      data: data
    }).done(function () {
      if (favorite.hasClass("live_btn_fav")) {
        favorite.removeClass("live_btn_fav").addClass("live_btn_out");
        favorite.html('マイリスト削除');
      } else {
        favorite.removeClass("live_btn_out").addClass("live_btn_fav");
        favorite.html('マイリスト登録');
      }
    });
  });
});
