import { csrfToken } from 'rails-ujs';

$(() => {
  // パスワード入力ボタンクリック時
  $('.link-movie .input_password').on('click', function () {
    const password = window.prompt('パスワードを入力してください', '');
    if (typeof password === 'undefined' || password === null || password === '') {
      alert('パスワードが入力されていません。');
      return;
    }

    const id = $(this).data('movie_id');
    $.ajax({
      type: 'POST',
      url: '/api/movies/auth_password',
      headers: {
        'X-CSRF-Token': csrfToken()
      },
      data: {
        'movie_id': id,
        'password': password
      },
      success: function () {
        $(`#thumbnail_${id}`).hide();
        $(`#link_${id}`).hide();
        $(`#player_${id}`).show();
      },
      error: function () {
        alert("パスワードの認証に失敗しました。");
      },
      async: false,
      timeout: 10000,
    });
  });
});
